import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createHashHistory } from "history";
import i18next from "i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Slide,
  withStyles,
} from "@material-ui/core";
import {
  getMaillists,
  cleanSelectedMaillist,
  deleteMaillist,
  getSelectedMaillist,
} from "../../actions/MaillistsActions";
import AddBlue from "../../assets/addBlue.svg";
import TrashGreyDark from "../../assets/trashGreyDark.svg";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import "../../styles/Maillists.css";
import {getDomains, getUsers} from "../../actions/UsersActions";

const mapStateToProps = (state) => ({
  maillistsFetching: state.maillists.maillistsFetching,
  maillists: state.maillists.maillists,
  selectedMaillist: state.maillists.selectedMaillist,
  deletingMaillist: state.maillists.deletingMaillist,
  usersFetching: state.users.usersFetching,
  users: state.users.users,
  selectedDisplayName: state.maillists.selectedDisplayName,
  selectedEmail: state.maillists.selectedEmail,
  selectedAlias: state.maillists.selectedAlias,
});

const mapDispatchToProps = (dispatch) => ({
  getMaillists: () => dispatch(getMaillists()),
  cleanSelectedMaillist: () => dispatch(cleanSelectedMaillist()),
  deleteMaillist: (maillistEmail) => dispatch(deleteMaillist(maillistEmail)),
  getSelectedMaillist: (displayName) => dispatch(getSelectedMaillist(displayName)),
  getUsers: (page, search) => dispatch(getUsers(page, search)),
  getDomains: () => dispatch(getDomains()),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  container: {
    maxHeight: 440,
  },
  mainButton: {
    backgroundColor: "#E2333A !important",
    color: "white",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    "&:hover": {
      backgroundColor: "#A52A2A !important",
      color: "black",
    }
  },
  cancelButton: {
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    "&:hover": {
      backgroundColor: "#00CED1 !important",
      color: "white",
      outline: "none",
    },
  },
});

class Maillists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trashWindow: false,
      selectedMaillistDisplayName: "",
      selectedMaillistEmail: "",
    };
    this.history = createHashHistory();
    this.handleNewMaillistClick = this.handleNewMaillistClick.bind(this);
    this.selectMaillist = this.selectMaillist.bind(this);
    this.handleMaillistEdit = this.handleMaillistEdit.bind(this);
  }
  componentDidMount() {
    this.props.getMaillists();
    this.props.cleanSelectedMaillist();
    this.props.getUsers(1);
  }
  handleNewMaillistClick(e) {
    e.preventDefault();
    this.history.push("/maillists/maillist/new");
  }
  selectMaillist = async (e, mail, name) => {
    this.props.getSelectedMaillist(name)
    e.preventDefault();
    this.setState({
      selectedMaillistDisplayName: name,
      selectedMaillistEmail: mail,
    });
    await this.props.getDomains();
    this.history.push(`/maillists/${mail}`);
  }
  handleMaillistEdit(e, mail) {
    e.preventDefault();
    this.props.history.push(`/maillists/${mail}`);
  }
  handleMaillistDelete(e) {
    e.preventDefault();
    this.setState({ trashWindow: false });
    this.props.deleteMaillist(this.state.selectedMaillistEmail);
  }
  render() {
    return this.props.maillistsFetching ? (
      <div className="maillistsLoading">
        <CircularProgress />
      </div>
    ) : (
      <div className="maillistTableMain">
        <Toolbar
          // style={{ minHeight: "55px", height: "3vh" }}
          className="toolbar"
        >
          {/* <Tooltip title={i18next.t("add")}>
            <IconButton
              onClick={this.handleNewMaillistClick}
              aria-label="Add"
              styles={{ flex: "1 1 100%" }}
            >
              <img src={AddBlue} alt={AddBlue} />
            </IconButton>
          </Tooltip>
            <div className="addMaillistPlus">{i18next.t("new_maillist")}</div> */}
            
            <Tooltip title={i18next.t("add_new_maillist")}>
            <Button
              className="newUserBtn"
                color="default"
                startIcon={<AddCircleOutlineIcon color="primary"/>}
                onClick={this.handleNewMaillistClick}
            >
              {i18next.t("new_maillist")}
            </Button>
          </Tooltip>

        </Toolbar>
  
        <TableContainer
          className="maillistsMain"
          style={{ maxWidth: "400px", width: "100%", height: "93vh", paddingLeft: "10px" }}
          component={Paper}
        >
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="mailListHeader" align="left" colSpan="2">
                  {i18next.t("name")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {this.props.maillists.length > 0 ? this.props.maillists.map((row, index) => (
               /\//.test(row.mail) ? "" : (
                <TableRow
                  hover
                  selected={this.props.selectedMaillist === row.displayName}
                  key={index}
                >
                  <TableCell
                    align="left"
                    className="maillistCells"
                    onClick={(event) => this.handleMaillistEdit(event, row.mail)}
                  >
                      {row.displayName}
                  </TableCell>
                  <TableCell align="left" className="maillistIconCells">
                    <Tooltip title={i18next.t("delete")}>
                      <IconButton
                        aria-label="Delete"
                        onClick={() =>
                          this.setState({
                            trashWindow: true,
                            selectedMaillistDisplayName: row.displayName,
                            selectedMaillistEmail: row.mail,
                          })
                        }
                      >
                        <img
                          className="trashImgHover"
                          src={TrashGreyDark}
                          alt={TrashGreyDark}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>)
                      ) ): null} 
            </TableBody>
          </Table>
        </TableContainer> 

        <Dialog
          className="dialogDelete"
          open={this.state.trashWindow}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ trashWindow: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {i18next.t("delete_list")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {i18next.t("real_delete")}{" "}
              <b>{this.state.selectedMaillistDisplayName}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ trashWindow: false })}
              className={this.props.classes.cancelButton}
              variant="outlined"
            >
              {i18next.t("cancel")}
            </Button>
            <Button
              onClick={(e) => {
                this.state.trashWindow && this.handleMaillistDelete(e);
              }}
              className={this.props.classes.mainButton}
            >
              {i18next.t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Maillists))
);