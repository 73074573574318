import {
  AUTHORIZE,
  NO_LOGIN_OR_PASSWORD_AUTH,
  BEGIN_LOGIN,
  SUCCESS_LOGIN,
  UNSUCCESS_LOGIN,
  LOGOUT,
  SELECT_LANGUAGE,
} from "../constants/LoginConstants";
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import i18next from "i18next";
import axios from "axios";

export const getAuthorized = (login, password) => ({
  type: AUTHORIZE,
  payload: { login: login, password: password },
});

export const noLoginOrPasswordAuth = (noLogin, noPassword) => ({
  type: NO_LOGIN_OR_PASSWORD_AUTH,
  payload: { noLogin, noPassword },
});

export const beginLogin = (login, password) => {
  return function (dispatch) {
    let history = createHashHistory();
    dispatch({ type: BEGIN_LOGIN });
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);

    return axios.post(Config.loginUrl(), formData,{  withCredentials: true,
      nctPublic: true,})
      .then((response) => {
        console.log("response", response);
        if (parseInt(response.status / 200) === 1) {
          // localStorage.setItem("token", response.data.result["token"]);
          localStorage.setItem("refresh_token", response.data.result["refresh_token"]);
          localStorage.setItem("access_token", response.data.result["access_token"]);
          localStorage.setItem("expire", response.data.result["expire"]);
       
          localStorage.setItem("tenant", response.data.result["tenant-id"]);
          dispatch(successLogin(response.data.result.token));
          history.push("/users");
          // return response.json();
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(unsuccessLogin());
        dispatch(showAlertAction(i18next.t("fail_auth"), "warning"));
      });
  };
};

export const successLogin = (token) => ({
  type: SUCCESS_LOGIN,
  payload: { token },
});

export const unsuccessLogin = () => ({
  type: UNSUCCESS_LOGIN,
});

export const logout = () => {
  const history = createHashHistory();
  localStorage.removeItem("tenant");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("expire");
  //add tokens
  history.push("/login");
  return {
    type: LOGOUT,
  };
};
export const getLanguage = (data) => ({
  type: SELECT_LANGUAGE,
  payload: data,
});
