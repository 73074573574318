import React from "react";
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { createHashHistory } from "history";
import { addResourceToGroup } from "../../actions/ResourceGroupsActions";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import GoBack from "../../assets/back.svg";
import { getDomains } from "../../actions/UsersActions.js";
import ResourcesAvatar from "../../assets/resourcesAvatar.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import i18next from "i18next";

const mapStateToProps = (state) => ({
  domains: state.users.domains,
  domainsFetching: state.users.domainsFetching,
  addingResourceToGroup: state.resourceGroups.addingResourceToGroup,
});

const mapDispatchToProps = (dispatch) => ({
  addResourceToGroup: (l, displayName, description, groupName, mail, domain) =>
    dispatch(addResourceToGroup(l, displayName, description, groupName, mail, domain)),
  getDomains: () => dispatch(getDomains()),
});

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
});

class NewResource extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      domain: "",
      displayName: "",
      mail: "",
      description: "",
      l: "",
      openUploadWindow: false,
      file: "",
      imagePreviewUrl:
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4%0AbWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5r%0APSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRweCIg%0AaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEu%0AMSI+CiAgICAKICAgIDx0aXRsZT5BRE0vcmVzdXJjZXMtYWxsLWNhdGVnb3Jp%0AZXM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rl%0Ac2M+CiAgICA8ZyBpZD0iQURNL3Jlc3VyY2VzLWFsbC1jYXRlZ29yaWVzIiBz%0AdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmls%0AbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMTAsOCBMMTQs%0AOCBMMTQsNiBMMTAsNiBMMTAsOCBaIE0xNCw0IEMxNS4xMDQ1Njk1LDQgMTYs%0ANC44OTU0MzA1IDE2LDYgTDE2LDggTDE4LDggQzE5LjEwNDU2OTUsOCAyMCw4%0ALjg5NTQzMDUgMjAsMTAgTDIwLDE3IEMyMCwxOC4xMDQ1Njk1IDE5LjEwNDU2%0AOTUsMTkgMTgsMTkgTDYsMTkgQzQuODk1NDMwNSwxOSA0LDE4LjEwNDU2OTUg%0ANCwxNyBMNCwxMCBDNCw4Ljg5NTQzMDUgNC44OTU0MzA1LDggNiw4IEw4LDgg%0ATDgsNiBDOCw0Ljg5NTQzMDUgOC44OTU0MzA1LDQgMTAsNCBMMTQsNCBaIE0x%0AOCwxMCBMNiwxMCBMNiwxNyBMMTgsMTcgTDE4LDEwIFoiIGlkPSJTaGFwZSIg%0AZmlsbD0iI2ZmZiIvPgogICAgPC9nPgo8L3N2Zz4K",
      active: "edit",
    };
    this.handleAddResource = this.handleAddResource.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.backToGroups = this.backToGroups.bind(this);
  }
  handleAddResource(e) {
    e.preventDefault();
    let groupName = this.props.match.params.groupName;
    console.log("flag8", this.state.l, this.state.displayName, this.state.description, groupName, this.state.mail, this.state.domain.domain )
    this.props.addResourceToGroup(this.state.l, this.state.displayName, this.state.description, groupName, this.state.mail, this.state.domain.domain);
  }
  componentDidMount() {
    this.props.getDomains();

  }
  componentDidUpdate(prevProps) {
    if (this.props.domains !== prevProps.domains) {
      this.setState({ domain: this.props.domains[0] });
    }
  }
  handleCancel(e) {
    e.preventDefault();
    this.history.goBack();
  }
  backToGroups(e) {
    e.preventDefault();
    
    let groupName = this.props.match.params.groupName;
    console.log("flag2", groupName )
    this.props.history.push(`/resource_groups/${groupName}`);
  }

  render() {
    let classes = this.props.classes;
    console.log("domen", this.state.domain)
    return (
      <div className={classes.root}>
        {this.props.addingResourceToGroup ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div className="createNewResource">
            <div className="goBack">
              <Tooltip title={i18next.t("back")}>
                <IconButton aria-label="Back" onClick={this.showNewGroupForm}>
                  <img src={GoBack} alt={GoBack} onClick={this.backToGroups} />
                </IconButton>
              </Tooltip>
              <div className="newResourceBack">{i18next.t("new_resource")}</div>
            </div>
            <div className="newResourceForm">
              <div className="resourceAva">
                <img
                  className="resourcesAvatar"
                  src={ResourcesAvatar}
                  alt={ResourcesAvatar}
                  onClick={() => this.setState({ openUploadWindow: true })}
                />
              </div>
              <form className="resourceName" noValidate autoComplete="off">
                <TextField
                  className="resourceName"
                  id="resourceName"
                  label={i18next.t("name")}
                  type="text"
                  value={this.state.displayName}
                  onChange={(e) =>
                    this.setState({ displayName: e.target.value })
                  }
                />
                <div className="row" style={{display: "flex", alignItems: "center"}}>
                  <TextField
                    className="resourceAddress"
                    id="resourceAddress"
                    label="Адрес"
                    type="text"
                    value={this.state.mail}
                    onChange={(e) => this.setState({ mail: e.target.value })}
                  />
                  <div style={{marginTop: "20px"}}>@</div>
                  <FormControl className="addressDropdown">
                    <Select
                      className="selectedAddress"
                      labelId="label"
                      id="select"
                      defaultValue={this.state.domain.domain}
                      onChange={(e) =>
                        this.setState({ domain: e.target.value })
                      }
                    >
                      {this.props.domains.map((option, key) => (
                        <MenuItem
                          className="options"
                          key={"-1" + key}
                          value={option}
                        >
                          {option.domain}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <br />
                <TextField
                  className="resourceCity"
                  id="resourceCity"
                  label={i18next.t('city')}
                  type="text"
                  value={this.state.l}
                  onChange={(e) => this.setState({ l: e.target.value })}
                />
                <br />
                <TextField
                  className="resourceDescription"
                  id="resourceDescription"
                  label={i18next.t("description")}
                  type="text"
                  value={this.state.descriptionValue}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </form>
              <Button className="cancelButton" onClick={this.handleCancel}>
              {i18next.t("cancel")}
              </Button>
              <Button
                className="saveButton"
                type="submit"
                onClick={this.handleAddResource}
                disabled={!this.state.displayName}
              >
                {i18next.t("save")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewResource))
);
