export const BEGIN_MAILLISTS_FETCH = "BEGIN_MAILLISTS_FETCH";
export const SUCCESS_MAILLISTS_FETCH = "SUCCESS_MAILLISTS_FETCH";
export const ADDING_NEW_MAILLIST = "ADDING_NEW_MAILLIST";
export const ADDED_NEW_MAILLIST = "ADDED_NEW_MAILLIST";
export const CLEAN_SELECTED_MAILLIST = "CLEAN_SELECTED_MAILLIST";
export const DELETED_MAILLIST = "DELETED_MAILLIST";
export const DELETING_MAILLIST = "DELETING_MAILLIST";
export const SELECTED_MAILLIST = "SELECTED_MAILLIST";
export const UPDATED_MAILLIST = "UPDATED_MAILLIST";
export const UPDATING_MAILLIST = "UPDATING_MAILLIST";
