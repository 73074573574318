import {
  BEGIN_USERS_FETCH,
  SUCCESS_USERS_FETCH,
  BEGIN_USER_FETCH,
  SUCCESS_USER_FETCH,
  CLEAN_SELECTED_USER,
  BEGIN_DOMAINS_FETCH,
  SUCCESS_DOMAINS_FETCH,
  ADDING_NEW_USER,
  ADDED_NEW_USER,
  UPDATING_USER,
  UPDATED_USER,
  DELETED_USER,
  DELETING_USER,
} from "../constants/UsersConstants";

const initialState = {
  usersFetching: false,
  users: [],
  usersPages: 0,
  currentUsersPage: 0,
  user: {},
  userFetching: false,
  selectedUser: "",
  domains: [],
  domainsFetching: false,
  addingNewUser: false,
  updatingUser: false,
  deletingUser: false,
};

export const users = (state = initialState, action = {}) => {
  switch (action.type) {
    case BEGIN_USERS_FETCH:
      return Object.assign({}, state, {
        usersFetching: action.payload.usersFetching,
      });
    case SUCCESS_USERS_FETCH:
      return Object.assign({}, state, {
        usersFetching: action.payload.usersFetching,
        users: action.payload.users,
        usersPages: action.payload.usersPagesCount,
        currentUsersPage: action.payload.page,
      });
    case BEGIN_USER_FETCH:
      return Object.assign({}, state, {
        selectedUser: action.payload.selectedUser,
        userFetching: action.payload.userFetching,
      });
    case SUCCESS_USER_FETCH:
      return Object.assign({}, state, {
        userFetching: action.payload.userFetching,
        user: action.payload.user,
      });
    case CLEAN_SELECTED_USER:
      return Object.assign({}, state, {
        selectedUser: action.payload.selectedUser,
      });
    case BEGIN_DOMAINS_FETCH:
      return Object.assign({}, state, {
        domainsFetching: action.payload.domainsFetching,
      });
    case SUCCESS_DOMAINS_FETCH:
      return Object.assign({}, state, {
        domainsFetching: action.payload.domains.domainsFetching,
        domains: action.payload.domains.results,
      });
    case ADDING_NEW_USER:
      return Object.assign({}, state, {
        addingNewUser: true,
      });
    case ADDED_NEW_USER:
      return Object.assign({}, state, {
        addingNewUser: false,
      });
    case UPDATING_USER:
      return Object.assign({}, state, {
        updatingUser: true,
      });
    case UPDATED_USER:
      return Object.assign({}, state, {
        updatingUser: false,
      });
    case DELETING_USER:
      return Object.assign({}, state, {
        deletingUser: action.payload.deletingUser,
      });
    case DELETED_USER:
      return Object.assign({}, state, {
        deletingUser: action.payload.deletingUser,
      });
    default:
      return state;
  }
};
