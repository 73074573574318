export const BEGIN_USERS_FETCH = "BEGIN_USERS_FETCH";
export const SUCCESS_USERS_FETCH = "SUCCESS_USERS_FETCH";
export const BEGIN_USER_FETCH = "BEGIN_USER_FETCH";
export const SUCCESS_USER_FETCH = "SUCCESS_USER_FETCH";
export const ADDING_NEW_USER = "ADDING_NEW_USER";
export const ADDED_NEW_USER = "ADDED_NEW_USER";
export const CLEAN_SELECTED_USER = "CLEAN_SELECTED_USER";
export const BEGIN_DOMAINS_FETCH = "BEGIN_DOMAINS_FETCH";
export const SUCCESS_DOMAINS_FETCH = "SUCCESS_DOMAINS_FETCH";
export const UPDATING_USER = "UPDATING_USER";
export const UPDATED_USER = "UPDATED_USER";
export const DELETED_USER = "DELETED_USER";
export const DELETING_USER = "DELETING_USER";
export const MIN_LOGIN_LEN = 2;
export const MIN_PASSWORD_LEN = 5;
export const USERS_PER_PAGE = 10;