import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createHashHistory } from "history";
import PropTypes from "prop-types";
import i18next from "i18next";
import {
  Button,
  TextField,
  CircularProgress,
  withStyles,
  MenuItem,
  Chip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getMaillists,
  getSelectedMaillist,
  updateMaillist,
  deleteMaillist,
} from "../../actions/MaillistsActions";
import { getUsers, getDomains } from "../../actions/UsersActions";
import "../../styles/Maillists.css";
import { parser } from "../../allFunctions/parser.js"
import { mailParser } from "../../allFunctions/parser.js"

const mapStateToProps = (state) => ({
  maillistsFetching: state.maillists.maillistsFetching,
  maillists: state.maillists.maillists,
  usersFetching: state.users.usersFetching,
  users: state.users.users,
  selectedMaillist: state.maillists.selectedMaillist,
  selectedDisplayName: state.maillists.selectedDisplayName,
  selectedEmail: state.maillists.selectedEmail,
  selectedAlias: state.maillists.selectedAlias,
  updatingMaillist: state.maillists.updatingMaillist,
  deletingMaillist: state.maillists.deletingMaillist,
  domains: state.users.domains,
  domainsFetching: state.users.domainsFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getMaillists: () => dispatch(getMaillists()),
  getSelectedMaillist: (displayName) =>
    dispatch(getSelectedMaillist(displayName)),
  getUsers: (page, search) => dispatch(getUsers(page, search)),
  updateMaillist: (mail, displayName, newmail, alias) =>
    dispatch(updateMaillist(mail, displayName, newmail, alias)),
  deleteMaillist: (mail, alias) =>
    dispatch(deleteMaillist(mail, alias)),
  getDomains: () => dispatch(getDomains()),
});

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    padding: "10px",
    maxWidth: "700px",
    "& > *": {
      margin: "10px",
    },
  },
});

class MaillistForm extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      displayName: "",
      newmail: "",
      alias: [],
      maillistGroupName: "",
      maillistDomainEmail: "",
      maillistAlias: [],
      aliasToDel: [],
      aliasesFirst: [],
      mail: "",
      domain: "",
      buttonDisable: "",
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.compareToUpd = this.compareToUpd.bind(this);
    
  }
  componentDidMount() {
    this.props.getUsers(1);
    let maillistMail = this.props.match.params.maillistMail;
    this.setState({ mail: maillistMail });
    this.props.getDomains();
   
          this.props.maillists
            .filter((index) => index.mail === maillistMail)
            .map((item) => {
              return this.setState({
                maillistGroupName: item.displayName,
                maillistDomainEmail: item.mail.split("@")[0],
                domain: item.mail.split("@")[1],
                maillistAlias: item.alias,
                aliasesFirst: item.alias,
                maillistGroupNamePrev: item.displayName,
                maillistDomainEmailPrev: item.mail.split("@")[0],
                domainPrev: item.mail.split("@")[1],
                maillistAliasPrev: item.alias,
              });
            });
        
  }

  componentDidUpdate(prevProps) {
    if (
      // this.state.maillistGroupName === "" ||
      this.props.match.params.maillistMail !== prevProps.match.params.maillistMail
    ) {
      this.props.maillists
        .filter((index) => index.mail === this.props.match.params.maillistMail)
        .map((item) => {
          return this.setState({
            maillistGroupName: item.displayName,
            maillistDomainEmail: item.mail.split("@")[0],
            domain: item.mail.split("@")[1],
            maillistAlias: item.alias,
            maillistGroupNamePrev: item.displayName,
            maillistDomainEmailPrev: item.mail.split("@")[0],
            domainPrev: item.mail.split("@")[1],
            maillistAliasPrev: item.alias,
          });
        });
      this.setState({
        mail: this.props.match.params.maillistMail,
      });
    } 
     
   
  }

  handleCancel(e) {
    e.preventDefault();
    this.history.goBack();
  }

  nameChangeHandler(e) {
    e.preventDefault();
    if (e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g)|| (e.target.value.length > 255)) {
      return
    } else {
      this.setState({ maillistGroupName: parser(e.target.value) });
    }   
  }
  mailChangeHandler(e) {
    e.preventDefault();   
    if (e.target.value.match(/[^a-zA-Z0-9_.-\s+]/g)|| (e.target.value.length > 255)) {
      return
    } else {
      this.setState({ maillistDomainEmail: mailParser(e.target.value) });
      
    }
  }

  compareToUpd() {
    console.log("ssss", this.state.maillistAlias)
    // let deletedUsers = this.state.aliasesFirst.filter(el => !this.state.maillistAlias.includes(el))
    // let addedUsers = this.state.maillistAlias.filter(el => !this.state.aliasesFirst.includes(el))
    // if (deletedUsers.length > 0) {
    //   this.props.deleteMaillist(
    //       this.state.mail,
    //       JSON.stringify(deletedUsers)
    //     );
    // }
    this.props.updateMaillist(
      this.state.mail,
      this.state.maillistGroupName.trim(),
      this.state.maillistDomainEmail + "@" + this.state.domain,
      JSON.stringify(this.state.maillistAlias)
    );
    this.setState({
      mail: "",
    });
    this.props.getSelectedMaillist('')
  }

  render() {
    let classes = this.props.classes;
    return this.props.maillistsFetching ? (
      <div className={classes.root}>
        <div className="loading">
          <CircularProgress />
        </div>
      </div>
    ) : (
      <div className={classes.root}>
        <form className={classes.infoForm} noValidate autoComplete="off">         
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{marginLeft: "0px"}}>
              <TextField
                className="maillistGroupName"
                id="maillistGroupName"
                label={i18next.t("list_name")}
                type="text"
                value={this.state.maillistGroupName}
                onChange={(e) => {
                  // if (e.target.value === this.state.maillistGroupName) {
                  //   this.setState({ disabled: true })
                  // }
                  this.nameChangeHandler(e)            
                }}
              />
            </div>
            <div style={{marginLeft: "24px"}}>
              <TextField
                className="maillistDomainEmail"
                id="maillistDomainEmail"
                label={i18next.t("list_email")}
                type="text"
                value={this.state.maillistDomainEmail}
                  onChange={(e, prevState) => {
                    this.mailChangeHandler(e)
                    // this.setState({ maillistDomainEmail: e.target.value })
                  }
                }
              />
              </div>
              <div style={{marginLeft: "5px", marginRight: "7px", paddingBottom: "7px"}}>
              @
            </div>
            <div style={{marginTop: "13px", width: "220px"}}>
              <TextField
                select
                className="domainCellMaillist"
                value={this.state.domain ? this.state.domain : ""}
                disabled={this.props.domainsFetching}
                onChange={(e) => this.setState({ domain: e.target.value })}
              >
                {this.props.domains ? (
                  this.props.domains.map((option, key) => (
                    <MenuItem
                      className="options"
                      key={"-1" + key}
                      value={option.domain}
                    >
                      {option.domain}
                    </MenuItem>
                  ))
                ) : (
                  <div />
                )}
              </TextField>
            </div>
          </div>
          <div className="grid-container grid-container--fit">
            <div className="grid-element">
              <Autocomplete
                multiple
                id="tags-standard"
                value={this.state.maillistAlias}
                options={this.props.users.map((item) => item.mail)}
                filterOptions={(options) => {
                  return options.filter(
                    (item) => !this.state.maillistAlias.includes(item)
                  );
                }}
               
                getOptionLabel={(option) => option}
                noOptionsText={i18next.t("no_option")}
                clearText={i18next.t("clear")}
                  onChange={(event,value) =>{
                  console.log("loook", value)
                  this.setState({ maillistAlias: value })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="maillistMembers"
                    id="maillistMembers"
                    label={i18next.t("members_of_the_list")}
                    type="text"
                    // variant="standard"
                    placeholder={i18next.t("members_of_the_list")}
                    onChange={(e) => this.props.getUsers(null, e.target.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="saveCancelBtns">
            <Button className="cancelButton" onClick={this.handleCancel}>
              {i18next.t("cancel")}
            </Button>
            <Button
                disabled={
                  (this.state.maillistGroupName === this.state.maillistGroupNamePrev &
                  this.state.maillistDomainEmail === this.state.maillistDomainEmailPrev &
                  this.state.domain === this.state.domainPrev &
                  this.state.maillistAlias === this.state.maillistAliasPrev) ||            
                  this.state.maillistAlias.length === 0 ||
                  !this.state.maillistGroupName ||
                  this.state.maillistGroupName.length > 255 ||
                  !this.state.maillistDomainEmail
                }
                className="saveButton"
                type="submit"
                onClick={() => {
                  console.log("ssss", this.state.maillistAlias)

                  this.compareToUpd()
                }}
            >
              {i18next.t("save")}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}


export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MaillistForm))
);
 