import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Avatar,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  TableSortLabel,
  DialogContentText,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import {
  getResources,
  deleteResource,
  deleteGroup,
  renameGroup,
} from "../../actions/ResourceGroupsActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ResourcesAvatar from "../../assets/resourcesAvatar.svg";
// import TrashGrey from "../../assets/trashGrey.svg";
import TrashGreyDark from "../../assets/trashGreyDark.svg";
import AddBlue from "../../assets/addBlue.svg";
import Pencil from "../../assets/pencil.svg";
import { parser } from "../../allFunctions/parser.js"
import {
  blue,
  green,
  orange,
  purple,
  red,
  pink,
  deepPurple,
  indigo,
  cyan,
  lightGreen,
  yellow,
} from "@material-ui/core/colors";
import "../../styles/ResourceGroups.css";
import i18next from "i18next";

const styles = (theme) => ({
  root: {
    minWidth: "600px",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
  mainButton: {
    backgroundColor: "#E2333A !important",
    color: "white",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    "&:hover": {
      backgroundColor: "#A52A2A !important",
      color: "black",
    },
  },
  cancelButton: {
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",
    "&:hover": {
      backgroundColor: "#00CED1 !important",
    },
  },
});

const mapStateToProps = (state) => ({
  resourcesFetching: state.resourceGroups.resourcesFetching,
  resources: state.resourceGroups.resources,
  deletingGroup: state.resourceGroups.deletingGroup,
  deletingResource: state.resourceGroups.deletingResource,
});

const mapDispatchToProps = (dispatch) => ({
  getResources: (groupName) => dispatch(getResources(groupName)),
  deleteResource: (resourceMail, groupName) =>
    dispatch(deleteResource(resourceMail, groupName)),
  deleteGroup: (groupName) => dispatch(deleteGroup(groupName)),
  renameGroup: (groupName,newName) => dispatch(renameGroup(groupName, newName )),
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: i18next.t("resourceR"),
  },
  {
    id: i18next.t("email"),
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="resourceTableHead">
      <TableRow>
        <TableCell />

        {headCells.map((headCell) => (
          <TableCell
            className="resourcesGroupTableHead"
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ResourceGroupForm extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      newName: "",
      order: "asc",
      orderBy: "email",
      selected: [],
      page: 0,
      nameValue: "",
      addressValue: "",
      cityValue: "",
      descriptionValue: "",
      rRemoveId: undefined,
      rRemoveEmail: undefined,
      openTrashWindow: false,
      openTrashWindowResource: false,
      rowsPerPage: 10,
      openUploadWindow: false,
      resourceName: false,
      imagePreviewUrl:
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4%0AbWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5r%0APSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRweCIg%0AaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEu%0AMSI+CiAgICAKICAgIDx0aXRsZT5BRE0vcmVzdXJjZXMtYWxsLWNhdGVnb3Jp%0AZXM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rl%0Ac2M+CiAgICA8ZyBpZD0iQURNL3Jlc3VyY2VzLWFsbC1jYXRlZ29yaWVzIiBz%0AdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmls%0AbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMTAsOCBMMTQs%0AOCBMMTQsNiBMMTAsNiBMMTAsOCBaIE0xNCw0IEMxNS4xMDQ1Njk1LDQgMTYs%0ANC44OTU0MzA1IDE2LDYgTDE2LDggTDE4LDggQzE5LjEwNDU2OTUsOCAyMCw4%0ALjg5NTQzMDUgMjAsMTAgTDIwLDE3IEMyMCwxOC4xMDQ1Njk1IDE5LjEwNDU2%0AOTUsMTkgMTgsMTkgTDYsMTkgQzQuODk1NDMwNSwxOSA0LDE4LjEwNDU2OTUg%0ANCwxNyBMNCwxMCBDNCw4Ljg5NTQzMDUgNC44OTU0MzA1LDggNiw4IEw4LDgg%0ATDgsNiBDOCw0Ljg5NTQzMDUgOC44OTU0MzA1LDQgMTAsNCBMMTQsNCBaIE0x%0AOCwxMCBMNiwxMCBMNiwxNyBMMTgsMTcgTDE4LDEwIFoiIGlkPSJTaGFwZSIg%0AZmlsbD0iI2ZmZiIvPgogICAgPC9nPgo8L3N2Zz4K",
    };
    this.handleResourceEdit = this.handleResourceEdit.bind(this);
    this.handleGroupDelete = this.handleGroupDelete.bind(this);
    this.handleAddResource = this.handleAddResource.bind(this);
    this.newNameChange = this.newNameChange.bind(this);
    this.renameGroup = this.renameGroup.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let groupName = this.props.match.params.groupName;
      this.setState({ newName: localStorage.getItem("groupName") });
      this.props.getResources(groupName);
    }
  }
  handleGroupDelete(e) {
    let groupName = this.props.match.params.groupName;
    this.setState({ openTrashWindow: false });
    this.props.deleteGroup(groupName);
  }

  handleResourceEdit(e, resourceId) {
    e.preventDefault();
    let groupName = this.props.match.params.groupName;
    this.props.history.push(
      `/resource_groups/${groupName}/resource/${resourceId}/edit`
    );
  }
  handleAddResource(e) {
    e.preventDefault();
    let groupName = this.props.match.params.groupName;
    this.props.history.push(`/resource_groups/${groupName}/resource/new`);
  }
  handleResourceDelete(e) {
    e.preventDefault();
    let groupName = this.props.match.params.groupName;
    this.setState({ openTrashWindowResource: false });
    this.props.deleteResource(this.state.rRemoveEmail, groupName);
  }
  componentDidMount() {
    let groupName = this.props.match.params.groupName;
    this.setState({ newName: localStorage.getItem("groupName") });
    this.props.getResources(groupName);
  }

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc" });
    this.setState({ orderBy: property });
  };

  newNameChange = (e) => {
    e.preventDefault();
    if (e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g)) {
      return;
    } else {
      this.setState({ newName: parser(e.target.value) });
    }
  };

  renameGroup = (e) => {
    e.preventDefault();
    this.props.renameGroup(
      this.props.match.params.groupName,
      this.state.newName.trim()
    );
  };

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 100), page: 0 });
  };

  render() {
    let classes = this.props.classes;
    let handleResourceEdit = this.handleResourceEdit;
    const colours = [
      blue[300],
      green[300],
      orange[500],
      purple[300],
      red[300],
      pink[300],
      purple[300],
      deepPurple[400],
      indigo[500],
      blue[500],
      cyan[500],
      lightGreen[500],
      yellow[500],
    ];
    const getColour = (index) => colours[Math.floor(index % 10)];
    return (
      <div style={{ width: "100%" }} className={classes.root}>
        {(this.props.resourcesFetching ||
          this.props.deletingGroup ||
          this.props.deletingResource) && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        {!(
          this.props.resourcesFetching ||
          this.props.deletingGroup ||
          this.props.deletingResource
        ) && (
          <div className="resourcesColumn">
            <div className="titlePlusTrash">
              <Table className="title">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Tooltip title={i18next.t("add_new_resource")}>
                        <Button
                          className="newUserBtn"
                          color="default"
                          startIcon={<AddCircleOutlineIcon color="primary" />}
                          onClick={this.handleAddResource}
                        >
                          {i18next.t("new_resource")}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <TextField
                        // defaultValue={localStorage.getItem("groupName")}
                        // value={this.state.newName}
                        // onChange={(e) => this.setState({ newName: e.target.value })}
                        value={this.state.newName}
                        onChange={this.newNameChange}
                      />

                      <Button
                        disabled={
                          !this.state.newName ||                          
                          this.state.newName.length > 255
                        }
                        className="newUserBtn"
                        color="default"
                        onClick={this.renameGroup}
                      >
                        {i18next.t("rename_group")}
                      </Button>
                    </TableCell>
                    <TableCell />
                    <TableCell className="trashTitle">
                      <Tooltip title={i18next.t("delete")}>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => this.handleGroupDelete()}
                        >
                          <img
                            className="trashTitle"
                            src={TrashGreyDark}
                            alt={TrashGreyDark}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Dialog
                className="dialogDelete"
                open={
                  this.state.openTrashWindow ||
                  this.state.openTrashWindowResource
                }
                TransitionComponent={Transition}
                keepMounted
                onClose={() =>
                  this.setState({
                    openTrashWindow: false,
                    openTrashWindowResource: false,
                  })
                }
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  {i18next.t("group_deletion")}
                  {" / "}
                  {i18next.t("resource")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {i18next.t("real_delete") + " "}
                    {this.state.resourceName
                      ? `${i18next.t("resource")} ${this.state.resourceName}`
                      : `${i18next.t("resource_group")} ${
                          this.props.match.params.groupName
                        }`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      this.setState({
                        openTrashWindow: false,
                        openTrashWindowResource: false,
                        resourceName: false,
                      })
                    }
                    className={classes.cancelButton}
                  >
                    {i18next.t("cancel")}
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.state.openTrashWindow && this.handleGroupDelete(e);
                      this.state.openTrashWindowResource &&
                        this.handleResourceDelete(e);
                    }}
                    className={classes.mainButton}
                  >
                    {i18next.t("delete")}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Paper className="paperResources">
              <TableContainer className="renderNotEmpty">
                <Table
                  className="table"
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    className="tableHead"
                    numSelected={this.state.selected.length}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.resources.length}
                  />
                  <TableBody>
                    {stableSort(
                      this.props.resources,
                      getSorting(this.state.order, this.state.orderBy)
                    )
                      // .slice(
                      //   this.state.page * this.state.rowsPerPage,
                      //   this.state.page * this.state.rowsPerPage +
                      //     this.state.rowsPerPage
                      // )
                      .map((row, index) => {
                        const isItemSelected = this.isSelected(row.name);
                        const labelId = "enhanced-table-checkbox-" + index;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => {
                              // this.props.getSelectedResourceId(row.email);
                              this.handleClick(event, row.displayName);
                              // this.handleResourceEdit(event, row.email);
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            // key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell
                              className="avatarCell"
                              onClick={(event) => {
                                this.handleResourceEdit(event, row.email);
                              }}
                            >
                              <Avatar
                                className="avatarResource"
                                style={{ backgroundColor: getColour(index) }}
                              >
                                <img
                                  src={ResourcesAvatar}
                                  alt="ResourcesAvatar"
                                />
                              </Avatar>
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              onClick={(event) => {
                                this.handleResourceEdit(event, row.email);
                              }}
                            >
                              {row.displayName}
                            </TableCell>
                            <TableCell
                              className="emailCell"
                              align="left"
                              onClick={(event) => {
                                this.handleResourceEdit(event, row.email);
                              }}
                            >
                              {row.email}
                            </TableCell>

                            {/* <TableCell className="emailCell" align="left"
                            onClick={(event) => {                           
                              this.handleResourceEdit(event, row.email);
                              }}>
                              {row.l}
                            </TableCell> */}

                            <TableCell className="trashRight">
                              <Tooltip title={i18next.t("edit")}>
                                <IconButton
                                  aria-label="Edit"
                                  onClick={(event) =>
                                    handleResourceEdit(event, row.email)
                                  }
                                >
                                  <img
                                    className="trashImgHover"
                                    src={Pencil}
                                    alt={Pencil}
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell className="trashRight">
                              <Tooltip title={i18next.t("delete")}>
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() =>
                                    this.setState({
                                      openTrashWindowResource: true,
                                      resourceName: row.displayName,
                                      rRemoveEmail: row.email,
                                    })
                                  }
                                >
                                  <img
                                    className="trashImgHover"
                                    src={TrashGreyDark}
                                    alt={TrashGreyDark}
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourceGroupForm))
);
