export const Config = {
    loginUrl: () => {
      console.log("window",window)
        return `${window.PBM_API_URL}auth`
    },
    domainsUrl: () => {
       const tenant = localStorage.getItem('tenant')
       return `${window.PBM_API_URL}domains/${tenant}`
    },
    usersUrl: (page, search) => {
       const tenant = localStorage.getItem('tenant')
       const searchString = search ? `&search=${search}` : ""
       return `${window.PBM_API_URL}users/${tenant}?page=${page}${searchString}`
    },
    userUrl: (tenantId, email) => {
       const tenant = localStorage.getItem('tenant')
       return `${window.PBM_API_URL}users/${tenant}/${email}`
    },
 
    editDeleteUserUrl: (mail) =>{
      const tenant = localStorage.getItem('tenant')
      return `${window.PBM_API_URL}users/${tenant}/${mail}`
   },
    
    
      
   groupsUrl: () => {
      const tenant = localStorage.getItem('tenant')
      return `${window.PBM_API_URL}resourcegroups/${tenant}`
   },    
   deleteGroupUrl: (groupName) => {
       const tenant = localStorage.getItem('tenant')
       return `${window.PBM_API_URL}resourcegroups/${tenant}/${groupName}`
   },
   addGroupUrl: (name) => {
       const tenant = localStorage.getItem('tenant')
       return `${window.PBM_API_URL}resourcegroups/${tenant}/${name}`
    },
   renameGroupUrl: (groupName, newName) => {
        const tenant = localStorage.getItem('tenant')
        return `${window.PBM_API_URL}resourcegroups/${tenant}/${groupName}?name=${newName}`
    },
   
   
    
  
    
    
    resourcesUrl: (groupName) => {
       const tenant = localStorage.getItem('tenant')
       return `${window.PBM_API_URL}resources/${tenant}/${groupName}`
    },
    editDeleteResourceUrl: (resourceMail, groupName) =>{
       const tenant = localStorage.getItem('tenant')
       return `${window.PBM_API_URL}resources/${tenant}/${groupName}/${resourceMail}`
   },
    
   groupResourcesUrl: (groupName, mail) => {
        const tenant = localStorage.getItem('tenant')
        return `${window.PBM_API_URL}resources/${tenant}/${groupName}/${mail}`
    },
    updateResourceUrl: (groupName, mail) => {
        const tenant = localStorage.getItem('tenant')
        return `${window.PBM_API_URL}resources/${tenant}/${groupName}/${mail}`
    },
    
    
    
    
    
    
    
    
    
    
    
    maillistsUrl: () => {
        const tenant = localStorage.getItem('tenant')
        return `${window.PBM_API_URL}maillists/${tenant}`
    },
    newMaillistsUrl: (mail) => {
        const tenant = localStorage.getItem('tenant')
        return `${window.PBM_API_URL}maillists/${tenant}/${mail}`
    },
}

