import {
  BEGIN_GROUPS_FETCH,
  SUCCESS_GROUPS_FETCH,
  BEGIN_RESOURCES_FETCH,
  SUCCESS_RESOURCES_FETCH,
  CLEAN_SELECTED_GROUP,
  DELETING_RESOURCE,
  DELETED_RESOURCE,
  DELETING_GROUP,
  DELETED_GROUP,
  BEGIN_RESOURCE_FETCH,
  SUCCESS_RESOURCE_FETCH,
  ADDING_NEW_GROUP,
  ADDED_NEW_GROUP,
  ADDING_RESOURCE_TO_GROUP,
  ADDED_RESOURCE_TO_GROUP,
  UPDATING_RESOURCE,
  UPDATED_RESOURCE,
} from "../constants/ResourceGroupsConstants";

const initialState = {
  groupsFetching: false,
  groups: [],
  resourcesFetching: false,
  resources: [],
  selectedGroup: "",
  addingNewgroup: false,
  addingResourceToGroup: false,
  deletingResource: false,
  deletingGroup: false,
  resource: {},
  resourceFetching: false,
  updatingResource: false,
};

export const resourceGroups = (state = initialState, action = {}) => {
  switch (action.type) {
    case BEGIN_GROUPS_FETCH:
      return Object.assign({}, state, {
        groupsFetching: action.payload.groupsFetching,
      });
    case SUCCESS_GROUPS_FETCH:
      return Object.assign({}, state, {
        groupsFetching: action.payload.groupsFetching,
        groups: action.payload.groups,
      });
    case BEGIN_RESOURCES_FETCH:
      return Object.assign({}, state, {
        selectedGroup: action.payload.selectedGroup,
        resourcesFetching: action.payload.resourcesFetching,
      });
    case SUCCESS_RESOURCES_FETCH:
      return Object.assign({}, state, {
        resourcesFetching: action.payload.resourcesFetching,
        resources: action.payload.resources,
      });
    case BEGIN_RESOURCE_FETCH:
      return Object.assign({}, state, {
        resourceFetching: action.payload.resourceFetching,
      });
    case SUCCESS_RESOURCE_FETCH:
      console.log('flag19', action.payload)
      return Object.assign({}, state, {
        resourceFetching: action.payload.resourceFetching,
        resource: action.payload.resource,
      });
    case CLEAN_SELECTED_GROUP:
      return Object.assign({}, state, {
        selectedGroup: action.payload.selectedGroup,
      });
    case DELETING_RESOURCE:
      return Object.assign({}, state, {
        deletingResource: action.payload.deletingResource,
      });
    case DELETED_RESOURCE:
      return Object.assign({}, state, {
        deletingResource: action.payload.deletingResource,
      });
    case DELETING_GROUP:
      return Object.assign({}, state, {
        deletingGroup: action.payload.deletingGroup,
      });
    case DELETED_GROUP:
      return Object.assign({}, state, {
        deletingGroup: action.payload.deletingGroup,
      });
    case ADDING_NEW_GROUP:
      return Object.assign({}, state, {
        addingNewGroup: true,
      });
    case ADDED_NEW_GROUP:
      return Object.assign({}, state, {
        addingNewGroup: false,
      });
    case ADDING_RESOURCE_TO_GROUP:
      return Object.assign({}, state, {
        addingResourceToGroup: true,
      });
    case ADDED_RESOURCE_TO_GROUP:
      return Object.assign({}, state, {
        addingResourceToGroup: false,
      });
    case UPDATING_RESOURCE:
      return Object.assign({}, state, {
        updatingResource: true,
      });
    case UPDATED_RESOURCE:
      return Object.assign({}, state, {
        updatingResource: false,
      });
    default:
      return state;
  }
};
