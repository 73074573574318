import React from "react";
import { TextField, Button, MenuItem, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { addNewMaillist } from "../../actions/MaillistsActions.js";
import i18next from "i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getUsers } from "../../actions/UsersActions";
import { getDomains } from "../../actions/UsersActions.js";
import { parser } from "../../allFunctions/parser.js"
import { mailParser } from "../../allFunctions/parser.js"
import { createHashHistory } from "history";

const mapStateToProps = (state) => ({
  users: state.users.users,
  domains: state.users.domains,
  domainsFetching: state.users.domainsFetching,
});

const mapDispatchToProps = (dispatch) => ({
  addNewMaillist: (mail, alias, displayName) =>
    dispatch(addNewMaillist(mail, alias, displayName)),
  getUsers: (page, search) => dispatch(getUsers(page, search)),
  getDomains: () => dispatch(getDomains()),
});

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    width: "100%",
    padding: "10px",
    maxWidth: "700px",
    "& > *": {
      margin: "10px",
    },
  },
});

class NewMaillist extends React.Component {
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      maillistEmail: "",
      maillistAlias: [],
      maillistName: "",
      domain: "",
      maillistAliasAdded: false,
    };
    this.handleaddNewMaillist = this.handleaddNewMaillist.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  componentDidMount() {
    this.props.getUsers(1);
    this.props.getDomains();
  }
  componentDidUpdate(prevProps) {
    if(this.props.domains) {
      if (this.props.domains !== prevProps.domains) {
        this.setState({ domain: this.props.domains[0] });
      }
    }
  }
  handleaddNewMaillist(e) {
    e.preventDefault();

    if (
      this.state.maillistAlias.length &&
      this.state.maillistEmail &&
      this.state.maillistName
    ) {
      
      this.props.addNewMaillist(
        this.state.maillistEmail + "@" + this.state.domain.domain,
        this.state.maillistAlias,
        this.state.maillistName.trim()
      );
    } else {
      alert(i18next.t("check_fields_filled"));
    }
  }
  handleDomainNameChange(e) {
    e.preventDefault();
    if (e.target.value.match(/[^/a-zA-ZА-Яа-я0-9_.-\s+]/g) || (e.target.value.length > 255)) {
      return
    } else {
      this.setState({ maillistName: parser(e.target.value) })
    }
  }
 
  // mailChangeHandler(e) {
  //   e.preventDefault();
  //   if (e.target.value.match(/[^a-zA-Z0-9_.-\s+]/g)|| (e.target.value.length > 255)) {
  //     return
  //   } else {
  //     this.setState({ maillistDomainEmail: mailParser(e.target.value) });
      
  //   }
  // }
  
  handledomainEmailChange(e) {
    e.preventDefault();   
    if (e.target.value.match(/[^a-zA-Z0-9_.-\s+]/g) || (e.target.value.length > 255)) {
      return
    } else {
      this.setState({ maillistEmail: mailParser(e.target.value) });      
    }
    // this.setState({ maillistEmail: e.target.value });
  }
  // handleAliasChange(e, value) {
  //   e.preventDefault();
  //   for (let i = 0; i < value.length; i++) {
  //     if (this.state.maillistAlias.includes(value[i].mail) === false) {
  //       this.state.maillistAlias.push(value[i].mail);
  //       this.setState({ maillistAliasAdded: true });
  //     }
  //   }
  // }

  handleCancel(e) {
    e.preventDefault();
    this.history.goBack();
  }

  render() {
    let classes = this.props.classes;
    return (
      <div className={classes.root}>
        <form className={classes.infoForm} noValidate autoComplete="off">
          <div style={{ display: "flex", alignItems: "flex-end" }}>

            <div style={{marginLeft: "0px"}}>
              <TextField
                
                id="standard-name"
                name="domainName"
                label={i18next.t("list_name")}
                value={this.state.maillistName}
                onChange={(e) => this.handleDomainNameChange(e)}
              />
            </div>
            <div style={{marginLeft: "24px"}}>
              <TextField
                id="standard-mail"
                name="domainEmail"
                label={i18next.t("list_email")}
                value={this.state.maillistEmail}
                onChange={(e) => this.handledomainEmailChange(e)}
              />
            </div>
            <div style={{marginLeft: "5px", marginRight: "7px", paddingBottom: "7px"}}>
              @
            </div>
            <div style={{marginTop: "13px", width: "220px"}}>
              <Select
                select
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                // className="domainCellMaillist"
                value={this.state.domain ? this.state.domain : ''}
                disabled={this.props.domainsFetching}
                onChange={(e) => this.setState({ domain: e.target.value })}
              >
                {this.props.domains ? this.props.domains.map((option, key) => (
                  <MenuItem className="options" key={"-1" + key} value={option}>
                    {option.domain}
                  </MenuItem>
                )) : <div/>}
              </Select>
            </div>
          </div>
          <div className="grid-container grid-container--fit">
            <div style = {{width: "400px"}}>
              <Autocomplete
                className="newAliasArray"
                multiple
                filterSelectedOptions
                id="tags-standard"
                options={this.props.users.map((item) => item.mail)}
                noOptionsText={i18next.t("no_option")}
                clearText={i18next.t("clear")}
                getOptionLabel={(option) => option}
                filterOptions={(options) => {
                  return options.filter(
                    (item) => !this.state.maillistAlias.includes(item)
                  );
                }}
                onChange={
                  (event, value) => this.setState({ maillistAlias: value })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="maillistMembers"
                    variant="standard"
                    id="maillistMembers"
                    label={i18next.t("members_of_the_list")}
                    placeholder={i18next.t("members_of_the_list")}
                    onChange={(e) => this.props.getUsers(null, e.target.value)}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.buttonsBlock}>
          <Button className="cancelButton" onClick={this.handleCancel}>
              {i18next.t("cancel")}
            </Button>
            <Button
              onClick={this.handleaddNewMaillist}
              variant="outlined"
              color="primary"
              className="saveMaillistButton"
              disabled={
                !this.state.maillistName ||
                this.state.maillistName.length > 255 ||
                !this.state.maillistEmail ||
                !this.state.maillistAlias
              }
            >
              {i18next.t("save")}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NewMaillist)
);
