import React from "react";
import { connect } from "react-redux";
import {
  getUsers,
  cleanSelectedUser,
  deleteUser,
} from "../../actions/UsersActions";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  Input,
  Toolbar,
  Tooltip,
  IconButton,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Slide,

} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import PsnAvatar from "../Avatars/PsnAvatars.js"
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import { createHashHistory } from "history";
import "../../styles/Users.css";
// import AddBlue from "../../assets/addBlue.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrashGreyDark from "../../assets/trashGreyDark.svg";
import Pencil from "../../assets/pencil.svg";
import i18next from "i18next";
import {
  blue,
  green,
  orange,
  purple,
  red,
  pink,
  deepPurple,
  indigo,
  cyan,
  lightGreen,
} from "@material-ui/core/colors";

const mapStateToProps = (state) => ({
  usersFetching: state.users.usersFetching,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
  usersPages: state.users.usersPages,
  currentUsersPage: state.users.currentUsersPage,
  deletingUser: state.resourceGroups.deletingUser,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page, search) => dispatch(getUsers(page, search)),
  cleanSelectedUser: () => dispatch(cleanSelectedUser()),
  deleteUser: (userMail) => dispatch(deleteUser(userMail)),
});

const styles = {
  mainButton: {
    backgroundColor: "#E2333A !important",
    color: "white",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    "&:hover": {
      backgroundColor: "#A52A2A !important",
      color: "black",
    }
  },
  cancelButton: {
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",
    "&:hover": {
      backgroundColor: "#00CED1 !important",
    }
  },
  
  dialog: {
    minHeight:"300px"
  }

};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.selectUser = this.selectUser.bind(this);
    this.history = createHashHistory();
    this.showNewUserForm = this.showNewUserForm.bind(this);
    this.state = {
      selectedUser: "",
      search: "",
      isSearchSuccessful: false,
      trashWindow: false,
      selectedUserName: "",
      selectedUserMail: "",
      show:"",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleUserDelete = this.handleUserDelete.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
  }
  async componentDidMount() {
    this.props.cleanSelectedUser()
    if (sessionStorage.searchQuery){
      await this.setState({search: sessionStorage.searchQuery})
      this.setState({isSearchSuccessful: true})
    }
      await this.props.getUsers(1, this.state.search);
    this.setState({currentPage: this.props.currentUsersPage})
  }
  selectUser(e, email) {
    e.preventDefault();
    this.history.push(`/user/${email}/edit`);
    localStorage.setItem('emailCur', `${email}`);
  }
  showNewUserForm(e) {
    e.preventDefault();
    this.props.cleanSelectedUser();
    this.history.push("/user/new");
  }
  handlePageChange(e, value) {
    e.preventDefault();
    this.props.getUsers(value, this.state.search);
  }

  handleSearchClick(e) {
    e.preventDefault();
   
    if (this.state.search.length < 3) {
      this.setState({ show: "true" })    
      return
     } else {
       e.preventDefault()
       this.setState({ show: "" })
       this.props.getUsers(1, this.state.search);
       sessionStorage.setItem('searchQuery', this.state.search)
       this.setState({isSearchSuccessful: true})
     }
      
    }
    async handleSearchKeyDown(e){
      if (e.keyCode === 13){
        if (this.state.search.length <3) {
          // this.setState({ search: "" })
          this.setState({ show: "true" })
          this.props.cleanSelectedUser()
          sessionStorage.removeItem('searchQuery')
          // await this.props.getUsers(1)
          this.setState({ isSearchSuccessful: false })
          return
        } else {
          e.preventDefault()
          this.setState({ show: "" })
          await this.props.getUsers(1, this.state.search);
          sessionStorage.setItem('searchQuery', this.state.search)

          this.setState({isSearchSuccessful: true})
        }
      }
    }
  handleSearchChange(e) {
    e.preventDefault();
    this.setState({ search: e.target.value });
  }

  handleClearClick(e){
    e.preventDefault()
    this.setState({ search: "" })
    this.setState({show: ""})
    this.props.cleanSelectedUser()
    sessionStorage.removeItem('searchQuery')
    this.props.getUsers(1)
    this.setState({isSearchSuccessful: false})
  }

  handleUserDelete(e) {
    e.preventDefault();
    this.setState({ trashWindow: false });
    this.props.deleteUser(this.state.selectedUserMail);
  }
  render() {
      const colors = [
        blue[300],
        green[300],
        orange[500],
        purple[300],
        red[300],
        pink[300],
        purple[300],
        deepPurple[400],
        indigo[500],
        blue[500],
        cyan[500],
        lightGreen[500],
      ];
      const getColor = (index) => {
        return colors[Math.floor(index % 10)];
      };
    return (
      <div className="UsersTable" style={{ width: "100%"}}>
        <Dialog
          style={{minHeight: "300px"}}
          // classes={{paper:this.props.classes.dialog}}
          open={this.state.trashWindow}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ trashWindow: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {i18next.t("delete_user")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {i18next.t("real_delete")}
              <div style={{wordWrap: "break-word"}}><b>{this.state.selectedUserName}</b>?</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ trashWindow: false })}
              className={this.props.classes.cancelButton}
            >
              {i18next.t("cancel")}
            </Button>
            <Button
              onClick={(e) => {
                this.state.trashWindow && this.handleUserDelete(e);
              }}
              className={this.props.classes.mainButton}
            >
              {i18next.t("delete")}
            </Button>
          </DialogActions>
        </Dialog>

        <Toolbar className="toolbar">
        
          <Tooltip title={i18next.t("add_new_user")}>
            <Button
              className="newUserBtn"
                color="default"
                startIcon={<AddCircleOutlineIcon color="primary"/>}
                onClick={this.showNewUserForm}
            >
              {i18next.t("new_user")}
            </Button>
          </Tooltip>
          <Paper component="form" className="searchLine">
          <Input
              className="searchLineField"
              value={this.state.search}
              onKeyDown={this.handleSearchKeyDown}
              onChange={e => this.handleSearchChange(e)}
              placeholder={i18next.t("search")}
              startAdornment={
                <InputAdornment position="start">
                  <Tooltip title={i18next.t("search")}>
                    <IconButton
                      className="search-button"
                      onClick={this.handleSearchClick}
                      aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={i18next.t("clear_search")}>
                      <IconButton
                        aria-label="clear search"
                        edge="end"
                        size="small"
                        onClick={this.handleClearClick}
                      >
                      <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                } 
            /> 
            <Divider orientation="vertical" />
          </Paper>
        </Toolbar>
        <div
              style={{
                display: (this.state.show ? 'block' : 'none'),
                // width: "189px",
                marginLeft: "85%",
                marginTop: "0",
                paddingTop: "0",
                height:"24px",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "24px",
                color: "#D43A3E", 
              }}>             
            {i18next.t("min_3_symbols_for_search")}
            </div>
        <div className="results-title">
          {this.state.isSearchSuccessful && this.props.users.length > 0 ?
              i18next.t("search_result") : !this.props.usersFetching && this.props.users.length === 0 ? i18next.t("no_user_found") : ""}
        </div>
        <TableContainer
          className="userTableContainer"
          style={{ height: "calc(95vh - 64px)" }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table" className="userTable">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="left">{i18next.t("name")}</TableCell>
                <TableCell align="left">{i18next.t("surname")}</TableCell>
                <TableCell align="left">{i18next.t("email")}</TableCell>
                <TableCell align="left">{i18next.t("actions")}</TableCell>
              </TableRow>
            </TableHead>

            {this.props.usersFetching ? (
              <TableBody className="loading">
                <TableRow>
                  <TableCell className="loadingCell" colSpan="4">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody style={{cursor: "default"}}>
                {this.props.users.map((row, index) => (
                  <TableRow
                    selected={this.props.selectedUser === row.mail}
                    hover
                    key={index}
                  >
                    <TableCell
                      className="userAva"
                      // onClick={(event) => this.selectUser(event, row.mail)}
                    >
                      <Avatar
                          className="userAva"
                          style={{ backgroundColor: getColor(index) }}
                        >
                          {row.cn ? row.cn[0] : ""}
                          {row.sn ? row.sn[0] : ""}
                        </Avatar>
                      {/* <PsnAvatar email={row.mail} displayName={`${row.cn} ${row.sn}`}/> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="userCells"
                      onClick={(event) => this.selectUser(event, row.mail)}
                    >
                      {row.cn}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="userCells"
                      onClick={(event) => this.selectUser(event, row.mail)}
                    >
                      {row.sn}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="userCells"
                      onClick={(event) => this.selectUser(event, row.mail)}
                    >
                      {row.mail.substring(0, 30)}
                    </TableCell>
                    <TableCell align="left" className="userCells">
                      <Tooltip title={i18next.t("edit")}>
                        <IconButton
                          aria-label="Delete"
                          onClick={(event) => this.selectUser(event, row.mail)}
                        >
                          <img
                            className="trashImgHover"
                            src={Pencil}
                            alt={Pencil}
                          />
                        </IconButton>
                      </Tooltip>  
                      <Tooltip title={i18next.t("delete")}>
                        <IconButton
                          onClick={() =>
                            this.setState({
                              trashWindow: true,
                              selectedUserName: row.cn,
                              selectedUserMail: row.mail,
                            })
                          }
                        >
                          <img
                            className="trashImgHover"
                            src={TrashGreyDark}
                            alt={TrashGreyDark}
                          />
                        </IconButton>
                        </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div
          style={{ width: "100%", height: "5vh", backgroundColor: "#0000000a" }}
        >
          {/* <Pagination
           
            className="userPagination"
            disabled={this.props.usersFetching}
            count={this.props.usersPages}
            onChange={this.handlePageChange}
            page={this.props.currentUsersPage}
          /> */}

          <TablePagination
            // component="div"
            // count={100}
            // page={page}
            // onPageChange={handleChangePage}
            // rowsPerPage={rowsPerPage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            // className="userPagination"
            disabled={this.props.usersFetching}
            count={this.props.usersPages}
            onChange={this.handlePageChange}
            page={this.props.currentUsersPage}
            nextIconButtonProps={{ disabled: true}}
            backIconButtonProps={{ disabled: true }}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Users));
