import axios from "axios";
import { logout } from "../actions/LoginActions";
import { Config } from "../configs";
let flagPromise = null;

export const refreshAccessToken = async (refreshToken, oldAccessToken) => {
  //console.log("rm:config startrefresh");
  const bodyFormData = new FormData();
  bodyFormData.set("refresh_token", refreshToken);
  // bodyFormData.set("UserID", user_id);
  // bodyFormData.set("RefreshToken", refreshToken);
  console.log("check0", Config.loginUrl(), oldAccessToken);
  return await axios
    .post(Config.loginUrl(), bodyFormData, {
      withCredentials: true,
      nctPublic: true,
      headers: {
        "Authorization": `Bearer ${oldAccessToken}`, //oldAccess
      },
    })
    .then((res) => {
      // console.log("rm:config status", res);
      if (res.status != 200) {
        // console.log("rm: log out after non 200 refresh token", res.status);
        logout();
      } else {
        //  console.log("rm:succes refresh token", res);
        const { access_token, expire, refresh_token } = res.data.result;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("expire", expire);
        localStorage.setItem("refresh_token", refresh_token);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        // console.log("rm: set new auth_token to axios cache", AccessToken);
        return access_token;
      }
    })
    .catch((e) => {
      // console.log("rm: log out after err refresh token", e);
      logout();
    });
};

function resolveRefresh(r, oa) {
  flagPromise = new Promise(async (resolve, reject) => {
    let result = await refreshAccessToken(r, oa);

    resolve(result);
    // setTimeout(() => {
    //   resolve('foo');
    // }, 300);
  });
  return flagPromise;
}

axios.interceptors.request.use(
  async function (config) {
    // console.log("rm:init request", config);
    console.log("config", config);
    if (config.nctPublic || config?.data?.nctPublic) {
      // console.log("rm:skip public request");
      return config;
    }

    let r = localStorage.getItem("refresh_token");
    let oa = localStorage.getItem("access_token");
    let expire = localStorage.getItem("expire");
    console.log(
      "check",
      "r",
      r,
      "oa",
      oa,
      "expire",
      expire,
      "now",
      Math.floor(new Date().getTime() / 1000)
    );
    if (!r) {
      //check if refresh token exists
      logout();
    } else if (expire <= Math.floor(new Date().getTime() / 1000)) {
      // check expire!!!
      try {
        if (flagPromise != null) {
          //first to refresh
          return flagPromise.then((nrt) => {
            config.headers["Authorization"] = `Bearer ${nrt}`;
            return config;
          });
        } else {
          //queue to refresh
          config.headers["Authorization"] = `Bearer ${await resolveRefresh(
            r,
            oa
          )}`;
          flagPromise = null;
        }
      } catch (e) {
        // console.log("rm: error after trying to refresh token", e);
        logout();
      }
    } else if (oa) {
      //check access token
      config.headers["Authorization"] = `Bearer ${oa}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log(error);
    if (error?.response?.config?.nctPublic) {
      // console.log("rm: skip error in response for public request");
      return Promise.reject(error.response);
    }
    // let isIn4xxRange = false;
    // if (typeof error.response.status == "string") {
    //   isIn4xxRange = error.response.status.startsWith("4");
    // } else if (typeof error.response.status == "number") {
    //   let strStatus = error.response.status.toString();
    //   isIn4xxRange = strStatus.startsWith("4");
    // }
    // console.log("rm: falls in 400 range?", error.response.status, isIn4xxRange);
    if (error.response.status == 401 || error.response.status == 403) {
      console.log(
        "rm: log out due to 4xx error in response for private request"
      );
      logout();
    }
    return Promise.reject(error.response);
  }
);

export default axios;
