import {
  BEGIN_MAILLISTS_FETCH,
  SUCCESS_MAILLISTS_FETCH,
  CLEAN_SELECTED_MAILLIST,
  DELETED_MAILLIST,
  DELETING_MAILLIST,
  SELECTED_MAILLIST,
} from "../constants/MaillistsConstants";

const initialState = {
  maillistsFetching: false,
  maillists: [],
  selectedMaillist: "",
  deletingMaillist: false,
  selectedDisplayName: "",
  selectedEmail: "",
  selectedAlias: [],
};

export const maillists = (state = initialState, action = {}) => {
  switch (action.type) {
    case BEGIN_MAILLISTS_FETCH:
      return Object.assign({}, state, {
        maillistsFetching: action.payload.maillistsFetching,
      });
    case SUCCESS_MAILLISTS_FETCH:
      return Object.assign({}, state, {
        maillistsFetching: action.payload.maillistsFetching,
        maillists: action.payload.maillists,
      });
    case CLEAN_SELECTED_MAILLIST:
      return Object.assign({}, state, {
        selectedMaillist: action.payload.selectedMaillist,
      });
    case DELETING_MAILLIST:
      return Object.assign({}, state, {
        deletingMaillist: action.payload.deletingMaillist,
      });
    case DELETED_MAILLIST:
      return Object.assign({}, state, {
        deletingMaillist: action.payload.deletingMaillist,
      });
    case SELECTED_MAILLIST:
      return Object.assign({}, state, {
        selectedMaillist: action.payload,
      });
    default:
      return state;
  }
};
