import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Drawer, CssBaseline, List, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import SidebarLink from "./SidebarLink";
import "../../styles/Sidebar.css";
import LogoLeft from "../../assets/logo.svg";
import Logout from "../../assets/exit.svg";
import { logout } from "../../actions/LoginActions";
import UsersIcon from "../../assets/person.svg";
import ResourceGroupsIcon from "../../assets/resources.svg";
import i18next from "i18next";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import MaillistsIcon from "../../assets/maillists.svg";

const drawerWidth = 240;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

const sidebarItems = [
  {
    title: i18next.t("users"),
    path: "/users",
    icon: <img src={UsersIcon} alt={UsersIcon} />,
  },
  {
    title: i18next.t("resource_groups"),
    path: "/resource_groups",
    icon: <img src={ResourceGroupsIcon} alt={ResourceGroupsIcon} />,
  },
  {
    title: i18next.t("maillists"),
    path: "/maillists",
    icon: <img src={MaillistsIcon} alt={MaillistsIcon} />,
  },
];

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: "transparent !important",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "transparent !important",
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    backgroundColor: "transparent !important",
    marginLeft: "56px",
  },
  content: {
    flexGrow: 1,
  },
  changeLang: {
    marginTop: "auto",
    marginBottom: "40px",
    marginLeft:"2vw",
    display:"flex",
    width: "300px",
    height: "400px",
    // backgroundColor:"brown",
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langWindow: false,
    };
  }

  closeLangWindow() {
    this.setState({ langWindow: false });
  }

  render() {
    const { classes, logout } = this.props;

    return (
      <div className={classes.root}>
        <div className="greyBar">
          <img className="logoImgLeftBar" src={LogoLeft} alt={LogoLeft} />
          <div className="help">
            <ChangeLanguage
                positionStyle={this.props.classes.changeLang}            
            />

            <div className="logoutButton" onClick={logout}>
              <Tooltip title={i18next.t("logout")} placement="right">
                <img style={{height: "30px"}} className="logoutBtn" src={Logout} alt={Logout} />
              </Tooltip>
            </div>
          </div>
        </div>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <List>
            {sidebarItems.map((sidebarItem, index) => (
              <SidebarLink
                key={index}
                to={sidebarItem.path}
                primary={sidebarItem.title}
                icon={sidebarItem.icon}
              />
            ))}
          </List>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar))
);
