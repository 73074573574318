import React from "react";
import {
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  Avatar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  updateUser,
  getDomains,
  getUser,
  deleteUser,
} from "../../actions/UsersActions.js";
import {
  blue,
  green,
  orange,
  purple,
  red,
  pink,
  deepPurple,
  indigo,
  cyan,
  lightGreen,
} from "@material-ui/core/colors";
import { connect } from "react-redux";
import GoBack from "../../assets/back.svg";
import AddBlue from "../../assets/addBlue.svg";
import TrashRed from "../../assets/trashRed.svg";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { createHashHistory } from "history";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import AvatarUploader from "../Avatars/AvatarUploader.js";
import i18next from "i18next";
import { showAlertAction } from "../../actions/AlertsActions";
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { parser } from "../../allFunctions/parser.js"
import { phoneParser } from "../../allFunctions/parser.js"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    // Height: "100%",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
  delButton: {
    
    color: "red",
    marginLeft: "50vw",
    marginTop: "7px",
    textTransform: "none",
     width: "220px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.35px",
    "&:hover": {
      // backgroundColor: "#E2333A !important",
      backgroundColor: "#4fc3f7",

      color: "red",
      outline: "none",
    }
  },
  passHidden: {
    fontSize: "14px !important",
    fontFamily: "text-security-disc",
    "-webkit-text-security": "disc",
    "-moz-text-security": "disc",
    "text-security": "disc",    
  },
  mainButton: {
    backgroundColor: "#E2333A !important",
    color: "white",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    "&:hover": {
      backgroundColor: "#A52A2A !important",
      color: "black",
    }
  },
  cancelButton: {
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",
    "&:hover": {
      backgroundColor: "#00CED1 !important",
    }
  },
});

const mapStateToProps = (state) => ({
  domains: state.users.domains,
  domainsFetching: state.users.domainsFetching,
  user: state.users.user,
  updatingUser: state.users.updatingUser,
  userFetching: state.users.userFetching,
  deletingUser: state.resourceGroups.deletingUser,
});

const mapDispatchToProps = (dispatch) => ({
  getDomains: () => dispatch(getDomains()),
  updateUser: (userInfo) => dispatch(updateUser(userInfo)),
  getUser: (email) => dispatch(getUser(email)),
  deleteUser: (userMail) => dispatch(deleteUser(userMail)),
  showAlert: (message, type) => dispatch(showAlertAction(message, type))
});

class UserForm extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      cn: "",
      sn: "",
      l: "",
      ou: "",
      domain: "",
      initials: "",
      title: "",
      description: "",
      telephoneNumber: "",
      labeledURI: "",
      info: "",
      homePhone: "",
      email: "",
      employeeNumber: 0,
      aliases: [],
      password: "",
      passwordConfirm: "",
      passwordError: false,
      errorWindow: false,
      croppedAvatar: "",
      trashWindow: false,
      quota: "",
    };

    this.handleUserSubmit = this.handleUserSubmit.bind(this);
    this.handleAddAlias = this.handleAddAlias.bind(this);
    this.handleAliasEmailChange = this.handleAliasEmailChange.bind(this);
    this.handleAliasDomainChange = this.handleAliasDomainChange.bind(this);
    this.handleRemoveAlias = this.handleRemoveAlias.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
    this.history = createHashHistory();
    this.uniq_id = 0;
    this.handleUserDelete = this.handleUserDelete.bind(this);
    this.handleQuotaChange = this.handleQuotaChange.bind(this);
  }

  componentDidMount() {
   
    this.props.getDomains();
    let email = this.props.match.params.email;
    this.props.getUser(email);
  }

  async handleAvatarCrop(avatarUrl) {
    let avatarBlob = await fetch(avatarUrl).then((r) => r.blob());
    this.setState({ croppedAvatar: avatarBlob });
  }

  componentDidUpdate(prevProps) {
    
    
  
    if (this.props.user !== prevProps.user) {
      let defaultState = {};
      let userFields = [
        "mail",
        
        "sn",
        "cn",
        "l",
        "ou",
        "initials",
        "title",
        "description",
        "telephoneNumber",
        "labeledURI",
        "info",
        "homePhone",
        "employeeNumber",
        "aliases",
        "password",
        "quota"
      ];
      for (let i in userFields) {
        let userField = userFields[i];
        let currentUser = this.props.user
        let userFieldValue = currentUser[userField];
        if (userFieldValue !== undefined) {
          defaultState[userField] = userFieldValue;
        }
      }
      
      for (let i in this.props.user.alias) {
        let recievedAlias = this.props.user.alias[i];
        let alias = {};
        alias["email"] = recievedAlias.split("@")[0];
        alias["domain"] = recievedAlias.split("@")[1];
        alias["domains"] = this.props.domains.map((el) => {
          return { key: this.uniq_id + el.domain, value: el.domain };
        });
        this.state.aliases.push(alias);
      }
      defaultState.quota = (this.props.user.quota.size_limit / 1024).toFixed(2)
      defaultState.storageUsed = this.props.user.quota.size_value / 1000
      defaultState["email"] = this.props.user.mail.split("@")[0];
      defaultState["domain"] = this.props.user.mail.split("@")[1];
      this.setState(defaultState);
    }
    if(this.props.domains) {
      if (this.props.domains !== prevProps.domains) {
        this.setState({domain: this.props.domains[0].domain});
      }
    }
  }
  handleUserSubmit(e) {
    e.preventDefault();
    if (this.state.password && this.state.password !== this.state.passwordConfirm) {
      this.setState({ passwordError: true });
      return;
    }else this.props.updateUser(this.state);
  }

  handleQuotaChange(e) {
    e.preventDefault();
    let valueX = e.target.value;
    if (valueX.match(/^\d*[,/.]?\d*$/)) {
      valueX = valueX.replace(/,/g, ".");
      valueX = valueX.replace(/\//g, "");
      this.setState({ quota: valueX });
    }
  }

  handleAliasEmailChange(e, index) {
    e.preventDefault();
    let value = e.target.value;
    let currentAliases = this.state.aliases;
    currentAliases[index].email = value;
    this.setState(currentAliases);
  }
  handleAliasDomainChange(e, index) {
    e.preventDefault();
    let value = e.target.value;
    let currentAliases = this.state.aliases;
    currentAliases[index].domain = value;
    this.setState(currentAliases);
  }
  handleRemoveAlias(e, index) {
    e.preventDefault();
    let currentAliases = this.state.aliases;
    currentAliases.splice(index, 1);
    this.setState({ aliases: currentAliases });
  }
  handleAddAlias(e) {
    e.preventDefault();
    let currentAliases = this.state.aliases;
    let domains = this.props.domains;
    let indexedDomains = domains.map((el) => {
      this.uniq_id += 1;
      return { key: this.uniq_id + el.domain, value: el.domain };
    });
    currentAliases.push({
      domains: indexedDomains,
      key: Date.now() + "",
      domain: indexedDomains[0].value,
      email: "",
    });
    this.setState({ aliases: currentAliases });
  }
  handleUserDelete(e) {
    e.preventDefault();
    this.setState({ trashWindow: false });
    this.props.deleteUser(this.state.email +"@"+ this.state.domain);
    this.props.history.push("/users");
  }

  handleChangeRole = (event) => {
    this.setState({ role: event.target.value });
  };
  handleClickOpen = () => {
    this.setState({ errorWindow: true });
  };

  handleClose = () => {
    this.setState({ errorWindow: false });
  };

  nameChangeHandler = (e, v) => {    
    if (e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g)) return
    if (e.target.value.length > 255) return
    else if (v=== "name") this.setState({ cn: parser(e.target.value) })
    else if (v=== "surName") this.setState({ sn: parser(e.target.value) })
    else if (v=== "middleName") this.setState({ initials: parser(e.target.value) })
    else if (v=== "department") this.setState({ ou: parser(e.target.value) })
    else if (v=== "position") this.setState({ title: parser(e.target.value) })
    else if (v === "city") this.setState({ l: parser(e.target.value) })
    else if (v=== "description") this.setState({ description: parser(e.target.value) })
  }

  phoneChangeHandler = (e, v) => {    
    if (e.target.value.match(/[^/\+/0-9-()\s+]/g)) return
    if (e.target.value.length > 255) return
    else if (v=== "telephoneNumber") this.setState({ telephoneNumber: phoneParser(e.target.value).trim() })
    else if (v=== "homePhone") this.setState({ homePhone: phoneParser(e.target.value).trim() })
    
  }

  render() {
   
      const colors = [
        blue[300],
        green[300],
        orange[500],
        purple[300],
        red[300],
        pink[300],
        purple[300],
        deepPurple[400],
        indigo[500],
        blue[500],
        cyan[500],
        lightGreen[500],
      ];
      const getColor = (index) => {
        return colors[Math.floor(index % 10)];
      };
    let classes = this.props.classes;
    return (
      <div className={classes.root}>
        <Dialog
          className="dialogDelete"
          open={this.state.trashWindow}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ trashWindow: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {i18next.t("delete_user")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {i18next.t("real_delete")}
              <div style={{ wordWrap: "break-word" }}>
                <b>{this.props.user.cn}</b>?
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ trashWindow: false })}
              className={classes.cancelButton}
            >
              {i18next.t("cancel")}{" "}
            </Button>
            <Button
              onClick={(e) => {
                this.state.trashWindow && this.handleUserDelete(e);
              }}
              className={classes.mainButton}
            >
              {i18next.t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.userFetching || this.props.updatingUser ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            // style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              paddingBottom: "10px",
              paddingTop: "10px",
              // overflow: "scroll",
              // maxHeight: "100vh",
            }}
          >
            <div style={{display:"flex", alignItems: "center"}}>
              <div className="newUserForm">
                <Tooltip title={i18next.t("back")}>
                  <Button
                    className="backBtnIcon"
                    startIcon={<ArrowBackIcon color="primary" />}
                    onClick={(e) => this.history.push("/users")}
                  >
                   {i18next.t("back")}
                  </Button>
                  </Tooltip>
                  </div>
                <div style={{wordWrap: "break-word", marginLeft: "50px", fontWeight: "bold"}}>{this.props.user.cn}</div>
                <div className="deleteUserBtn">
                  <Tooltip title={i18next.t("delete")}>
                    <Button
                      className={classes.delButton}
                      // color="primary"
                      startIcon={<DeleteIcon />}
                      aria-label="Delete"
                      onClick={() => this.setState({ trashWindow: true })}
                    >
                      {i18next.t("delete_user")}
                    </Button>
                  </Tooltip>
                  
                  {/* </div> */}
                </div>
              </div>
            {/* </div> */}

            <div className="mainInfoAva">
              <div className="avaAndLogin">
                <AvatarUploader
                  onAvatarCrop={this.handleAvatarCrop}
                  email={`${this.state.email}@${this.state.domain}`}
                  displayName={`${this.state.cn} ${this.state.sn}`}
                />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="userTableCell">
                        <div className="loginRaw">
                          <TextField
                            label={i18next.t("login")}
                            className="loginField"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            disabled
                          />
                          <div
                            style={{
                              color: "rgba(22, 33, 41, 0.56)",
                              display: "inherit",
                              padding: 0,
                              fontSize: "12px",
                              width: "216px",
                            }}
                          >
                            <TextField
                              select
                              className="domainCell"
                              value={this.state.domain ? this.state.domain : ""}
                              disabled={this.props.domainsFetching}
                              onChange={(e) =>
                                this.setState({ domain: e.target.value })
                              }
                              // disabled
                            >
                              {this.props.domains ? (
                                this.props.domains.map((option, key) => (
                                  <MenuItem
                                    className="options"
                                    key={"-1" + key}
                                    value={option.domain}
                                  >
                                    {option.domain}
                                  </MenuItem>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </TextField>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="userTableCell">
                        <div className="roleRow">
                          <TextField
                            label={i18next.t("role")}
                            select
                            className="domainCell"
                            value={this.state.employeeNumber}
                            onChange={(e) =>
                              this.setState({ employeeNumber: e.target.value })
                            }
                          >
                            {[
                              { key: 0, value: i18next.t("user") },
                              { key: 1, value: i18next.t("admin") },
                            ].map((option) => (
                              <MenuItem
                                className="options"
                                key={option.key}
                                value={option.key}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            className="domainCell"
                            label={i18next.t("quota")}
                            name="quota"
                            autoComplete="cc-number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  style={{ fontSize: "0.875rem", width: "40%" }}
                                  position="start"
                                >
                                  {this.state.storageUsed} /
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  Mb
                                </InputAdornment>
                              ),
                            }}
                            // type="number"
                            value={this.state.quota}
                            onChange={this.handleQuotaChange}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="row">
                <div className="addNewUserForm">
                  {i18next.t("change_password")}
                </div>

                <div className="changeConfirmPassword">
                  <TextField
                    style={{ marginRight: "20px" }}
                    InputProps={{
                      classes: { input: classes.passHidden },
                    }}
                    value={this.state.password}
                    label={i18next.t("new_password")}
                    error={this.state.passwordError}
                    helperText={
                      this.state.passwordError && i18next.t("password_mismatch")
                    }
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        passwordError: false,
                      })
                    }
                  />

                  <TextField
                    label={i18next.t("confirm_password")}
                    InputProps={{ classes: { input: classes.passHidden } }}
                    error={this.state.passwordError}
                    value={this.state.passwordConfirm}
                    onChange={(e) =>
                      this.setState({
                        passwordConfirm: e.target.value,
                        passwordError: false,
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <div className="addNewUserForm">
                  <span>{i18next.t("mail_aliases")}</span>
                  <Tooltip title={i18next.t("add")}>
                    <IconButton
                      disabled={this.state.domainsFetching}
                      aria-label="Add"
                      onClick={this.handleAddAlias}
                    >
                      <img src={AddBlue} alt={AddBlue} />
                    </IconButton>
                  </Tooltip>
                </div>

                {this.state.aliases.map((alias, index) => (
                  <div className="loginRaw">
                    <TextField
                      label={i18next.t("login")}
                      className="loginField"
                      value={alias.email}
                      onChange={(e) => this.handleAliasEmailChange(e, index)}
                    />
                    <div
                      style={{
                        color: "rgba(22, 33, 41, 0.56)",
                        display: "inherit",
                        padding: "0",
                        fontSize: "12px",
                        width: "216px",
                      }}
                    >
                      <TextField
                        select
                        className="domainCell"
                        style={{ paddingRight: 0 }}
                        value={alias.domain}
                        disabled={this.props.domainsFetching}
                        onChange={(e) => this.handleAliasDomainChange(e, index)}
                      >
                        {alias.domains.map((option, key) => (
                          <MenuItem
                            className="options"
                            key={option.key}
                            value={option.value}
                          >
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <Tooltip title={i18next.t("delete")}>
                      <IconButton
                        aria-label="Remove"
                        onClick={(e) => this.handleRemoveAlias(e, index)}
                      >
                        <img src={TrashRed} alt={TrashRed} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))}
              </div>

              <div className="emailDomain"></div>
              <div className="addNewUserForm">
                {i18next.t("personal_information")}
              </div>
              <div className="row">
                <TextField
                  className="firstNameCell"
                  label={i18next.t("name")}
                  value={this.state.cn}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}                 
                  onChange={(e) => {
                    this.nameChangeHandler(e, "name")
                  }}
                />
                <TextField
                  className="secondNameCell"
                  label={i18next.t("surname")}
                  value={this.state.sn}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "surName")
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  className="patronymicCell"
                  label={i18next.t("middle_name")}
                  value={this.state.initials}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "middleName")
                  }}
                />
                <TextField
                  className="companyCell"
                  label={i18next.t("department")}
                  value={this.state.ou}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "department")
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  className="positionCell"
                  label={i18next.t("position")}
                  value={this.state.title}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "position")
                  }}
                />
                <TextField
                  className="descriptionCell"
                  label={i18next.t("description")}
                  value={this.state.description}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "description")
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  className="cityCell"
                  label={i18next.t("city")}
                  value={this.state.l}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "city")
                  }}
                />
              </div>
              <div className="contacts">{i18next.t("contacts")}</div>
              <div className="allContacts">
                <div className="contactBlock">
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label={i18next.t("work_phone")}
                      value={this.state.telephoneNumber}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => {
                        this.phoneChangeHandler(e, "telephoneNumber")
                      }}
                    />
                  </div>
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label={i18next.t("home_phone")}
                      value={this.state.homePhone}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => {
                        this.phoneChangeHandler(e, "homePhone")
                      }}
                    />
                  </div>
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label="Skype"
                      value={this.state.info}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => this.setState({ info: e.target.value })}
                    />
                  </div>
                </div>
                <div className="contactBlock">
                  {/* <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label="Facebook"
                      value={this.state.labeledURI}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) =>
                        this.setState({ labeledURI: e.target.value })
                      }
                    />
                  </div> */}
                  {/* <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label="Skype"
                      value={this.state.info}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => this.setState({ info: e.target.value })}
                    />
                  </div> */}
                </div>
              </div>
              <div className="addButton">
                <Button
                  style={{marginRight: '10%'}}
                  onClick={this.handleUserSubmit}
                  className="addButtonBtn"
                  disabled={
                    !this.state.cn || !this.state.sn || !this.state.email
                  }
                >
                  {i18next.t("refresh")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserForm))
);
