import React from "react";
import {
  Button,
  TextField,
  MenuItem,
  DialogActions,
  FormControl,
  Select,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { createHashHistory } from "history";
import {
  addResourceToGroup,
  getResource,
  updateResource,
} from "../../actions/ResourceGroupsActions";

import PropTypes from "prop-types";
import { withRouter } from "react-router";
import GoBack from "../../assets/back.svg";
import ResourcesAvatar from "../../assets/resourcesAvatar.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDomains } from "../../actions/UsersActions.js";
import i18next from "i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => ({
 
  resourceFetching: state.resourceGroups.resourceFetching,
  resource: state.resourceGroups.resource,
  addingResourceToGroup: state.resourceGroups.addingResourceToGroup,
  updatingResource: state.resourceGroups.updatingResource,
  domains: state.users.domains,
});

const mapDispatchToProps = (dispatch) => ({
  
  addResourceToGroup: (groupName, formData) =>
    dispatch(addResourceToGroup(groupName, formData)),
  getResource: (groupName, resourceId) =>
    dispatch(getResource(groupName, resourceId)),
  updateResource: (resourceId, groupName, formData) =>
    dispatch(updateResource(resourceId, groupName, formData)),
  getDomains: () => dispatch(getDomains()),
});

const styles = (theme) => ({
  root: {
    // minWidth: "600px",
    width: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
  fields: {
    width: "300px",  
  },
});

class EditResource extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      displayName: "",
      domain: "",
      email: "",
      description: "",
      l: "",
      openUploadWindow: false,
     
    };
    this.handleResourceFieldChange = this.handleResourceFieldChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.backToGroups = this.backToGroups.bind(this);
    this.handleUpdateResource = this.handleUpdateResource.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.resource !== prevProps.resource) {
      let email = this.props.resource.email.split("@")[0];
      let domain = this.props.resource.email.split("@")[1];
      let { displayName, l, description } = this.props.resource;
      this.setState({ email, domain, displayName, l, description });
    }
    if (this.props.location !== prevProps.location) {
      let groupName = this.props.match.params.groupName;
      let resourceId = this.props.match.params.resourceId;
      this.props.getResource(groupName, resourceId);
      this.props.getDomains();
    }
  }

  componentDidMount() {
    let groupName = this.props.match.params.groupName;
    let resourceId = this.props.match.params.resourceId;
   
    this.props.getResource(groupName, resourceId);
    this.props.getDomains();
  }

  handleUpdateResource(e) {
    let groupName = this.props.match.params.groupName;
    let resourceId = this.props.match.params.resourceId;
    this.props.updateResource(resourceId, groupName, this.state);
  }

  handleResourceFieldChange(e) {
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    let newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  }
  handleCancel(e) {
    e.preventDefault();
    this.history.goBack();
  }
  backToGroups(e) {
    e.preventDefault();
    let groupName = this.props.match.params.groupName;
    this.props.history.push(`/resource_groups/${groupName}`);
  }
  render() {
    let classes = this.props.classes;
    const { imagePreviewUrl, active } = this.state;

    const ImgUpload = ({ onChange, src }) => (
      <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
          <img
            alt="uploadphoto"
            className="photoUpload"
            htmlFor="photo-upload"
            src={src}
          />
        </div>
        <input id="photo-upload" type="file" onChange={onChange} />
      </label>
    );

    const Profile = ({ onSubmit, src }) => (
      <div className="card">
        <form className="ava" onSubmit={onSubmit}>
          <label className="custom-file-upload fas">
            <div className="img-wrap">
              <img
                alt="profilephoto"
                className="photoUpload"
                htmlFor="photo-upload"
                src={src}
              />
            </div>
          </label>
        </form>
      </div>
    );

    const Edit = ({ onSubmit, children }) => (
      <div className="card">
        <form className="ava" onSubmit={onSubmit}>
          {children}
          <button type="submit" className="save">
          {i18next.t("save")}
          </button>
        </form>
      </div>
    );
    return (
      <div className={classes.root}>
        {this.props.resourceFetching || this.props.updatingResource ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="createNewResource">
            <div className="goBack">
              <Tooltip title={i18next.t("back")}>
                <IconButton aria-label="Back" onClick={this.backToGroups}>
                  <img src={GoBack} alt={GoBack} />
                </IconButton>
              </Tooltip>
              <div className="newResourceBack">{i18next.t("resourceR")}</div>
            </div>
            <div className="newResourceForm">
              <div className="existingResourceAva">
                <img
                  className="resourcesAvatar"
                  src={ResourcesAvatar}
                  alt={ResourcesAvatar}
                  onClick={() => this.setState({ openUploadWindow: true })}
                />
              </div>
              <Dialog
                className="dialogUpload"
                open={this.state.openUploadWindow}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.setState({ openUploadWindow: false })}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  {"Upload avatar"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Image up to 10 MB. Formats: PNG, JPEG, GIF, SVG.
                  </DialogContentText>
                  <DialogContentText
                    component="div"
                    id="alert-dialog-slide-description"
                  >
                    <div>
                      {active === "edit" ? (
                        <Edit onSubmit={this.handleSubmit}>
                          <ImgUpload
                            onChange={this.photoUpload}
                            src={imagePreviewUrl}
                          />
                        </Edit>
                      ) : (
                        <Profile
                          onSubmit={this.handleSubmit}
                          src={imagePreviewUrl}
                        />
                      )}
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.setState({ openUploadWindow: false })}
                    className="cancelUpload"
                  >
                    {i18next.t("cancel")}
                  </Button>
                  <Button
                    onClick={() => this.setState({ openUploadWindow: false })}
                    className="okUpload"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <form noValidate autoComplete="off" >
                <TextField
                  className="resourceName"
                  id="resourceName"
                  label={i18next.t("name")}
                  type="text"
                  value={this.state.displayName}
                  onChange={(e) => this.setState({ displayName: e.target.value })}
                />
                <div className="row">
                  <TextField
                    className={classes.fields} 
                    id="resourceAddress"
                    label={i18next.t("address")}
                    type="text"
                    value={this.state.email + "@" + this.state.domain}
                    disabled    
                  />

                 
                </div>
                <br />
                <TextField
                  className="resourceCity"
                  id="resourceCity"
                  label={i18next.t("city")}
                  type="text"
                  value={this.state.l}
                  onChange={(e) => this.setState({ l: e.target.value })}
                />
                <br />
                <TextField
                  className="resourceDescription"
                  id="resourceDescription"
                  label={i18next.t("description")}
                  type="text"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  disabled={this.props.displayName}  
                />
              </form>
              <Button className="cancelButton" onClick={this.handleCancel}>
                {i18next.t("cancel")}
              </Button>
              <Button
                className="saveButton"
                type="submit"
                disabled={!this.state.displayName}
                onClick={this.handleUpdateResource}
              >
                 {i18next.t("save")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditResource))
);
