import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { getLanguage } from "../../actions/LoginActions";
import i18next from "i18next";
import { connect } from "react-redux";
import Globe from "../../assets/globe.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { LANGUAGES, DEFAULT_LANGUAGE } from "../../constants/LanguageConstants";

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.auth.selectedLanguage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLanguage: (data) => dispatch(getLanguage(data)),
});

let lang = localStorage.getItem("language") ? localStorage.getItem("language").substr(0, 2) : DEFAULT_LANGUAGE.substr(0, 2);

i18next.init({
  lng: lang,
  debug: true,
  resources: require(`../../translations/${lang}.json`),
});

class ChangeLanguage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      language: this.props.selectedLanguage
        ? this.props.selectedLanguage
        : DEFAULT_LANGUAGE,

      langWindow: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  closeLangWindow() {
    this.setState({ langWindow: false });
  }

  handleChange = (event) => {
    this.setState({ language: event.target.value });
  };

  selectedLanguage = (event) => {
    let language = event.target.innerText;
    if (language) {
      localStorage.setItem("language", event.target.innerText);
      localStorage.setItem("lang", event.target.innerText.substr(0, 2));
    }
    document.location.reload(true);
  };

  render() {
    return (
      <div className="changeLang">
        <Tooltip title={i18next.t("change_language")}>
          <div className="langBtn">
            <img
              className="langImg"
              src={Globe}
              alt={Globe}
              onClick={() => this.setState({ langWindow: true })}
            />
          </div>
        </Tooltip>
        <Dialog
          className={this.props.positionStyle}
          onClose={() => this.closeLangWindow()}
          aria-labelledby="simple-dialog-title"
          open={this.state.langWindow}
        >
          {/* <DialogTitle id="simple-dialog-title">
            {i18next.t("select_language")}
          </DialogTitle> */}
          <List component="nav" aria-label="secondary mailbox folders">
            {LANGUAGES.map((item) => (
              <ListItem button key={item}>
                <ListItemText
                  primary={item}
                  onClick={(event) => {
                    this.selectedLanguage(event);
                    this.props.getLanguage(event.target.innerText);
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage);
