import React from "react";
import "../../styles/Login.css";
import { TextField, Button } from "@material-ui/core";
import LogoEN from "../../assets/MyOffice-logoEN.svg";
import LogoRU from "../../assets/MyOffice-logoRU.svg";
import { connect } from "react-redux";
import { getAuthorized } from "../../actions/LoginActions.js";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import i18next from "i18next";
import Help from "../../assets/help.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import { withStyles } from "@material-ui/core/styles"

const mapStateToProps = (state) => ({
  noLogin: state.auth.noLogin,
  noPassword: state.auth.noPassword,
  isAuthing: state.auth.isAuthing,
});

const mapDispatchToProps = (dispatch) => ({
  getAuthorized: (login, password) => dispatch(getAuthorized(login, password)),
});

const styles = (theme) => ({
  changeLang: {
    marginLeft:"85vw",
    display:"flex",
    width: "300px",
    height: "400px",
  },
})

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      helpWindow: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.handleHelpWindow = this.handleHelpWindow.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.getAuthorized(this.state.login, this.state.password);
  };

  handleLoginChange(event) {
    this.setState({ login: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleEnterKey(e) {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  }
  handleHelpWindow() {
    this.setState({ helpWindow: !this.state.helpWindow });
  }
  render() {
    const { login, password, isAuthing, noLogin, noPassword } = this.props;
    return (
      <div className="mainLoginPage">
        <div className="loginHeader">
          <ChangeLanguage
               positionStyle={this.props.classes.changeLang}            
          />
          <Tooltip title={i18next.t("help")} onClick={() => this.handleHelpWindow()}>
            <img className="helpImg" src={Help} alt={Help} />
          </Tooltip>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="helpWindow"
            open={this.state.helpWindow}
            onClose={() => this.handleHelpWindow()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.helpWindow}>
              <div className="helpWindowPaper">
                <div className="question">
                  <h4 id="transition-modal-title">
                    {i18next.t("do_you_have_any_questions_or_suggestions")}
                  </h4>
                  {i18next.t("please_contact_support_for_assistance")}
                </div>
                <div className="askUs">
                  <a
                    class="OfsYHttf2q211N2ZDrVxF"
                    href="mailto:support@service.myoffice.ru?subject=%D0%9C%D0%BE%D0%B9%D0%9E%D1%84%D0%B8%D1%81:%20запрос%20в%20службу%20поддержки&amp;body=%0D%0A%0D%0A%0D%0AДанные%20ниже%20помогут%20быстрее%20обработать%20ваш%20запрос.%20Не%20удаляйте%20и%20не%20изменяйте%20их,%20пожалуйста.%0D%0A%0A%20%20%20%20%20%20%20%20%20%20%20%20http_host%3A%20%22auth.collabio.team%22%0A%20%20%20%20%20%20%20%20%20%20%20%20user_agent%3A%20%22Mozilla%2F5.0%20(Windows%20NT%2010.0%3B%20Win64%3B%20x64)%20AppleWebKit%2F537.36%20(KHTML%2C%20like%20Gecko)%20Chrome%2F83.0.4103.116%20Safari%2F537.36%22%0A%20%20%20%20%20%20%20%20%20%20%20%20APP_NAME%3A%20%22sso%22%0A%20%20%20%20%20%20%20%20%20%20%20%20VERSION%3A%20%22%2216.0.5.6%22%22%0A%20%20%20%20%20%20%20%20"
                    data-co-app-support-mail-link="true"
                  >
                    {i18next.t("email_support")}
                  </a>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        <div className="login">
          <div className="loginPage">
            <div>
              <img
                  className="logoImg"
                  src={i18next.language === "Ру" ? LogoRU : LogoEN}
                  alt={i18next.language === "Ру" ? LogoRU : LogoEN}
              />
            </div>

            <TextField
              name="login"
              value={login}
              onChange={this.handleLoginChange}
              onKeyDown={this.handleEnterKey}
              className="loginRows"
              id="standard-basic"
              label={i18next.t("login_or_email")}
            />
            {noLogin && (
              <span className="credentialsRequired">
                {i18next.t("login_or_email_required")}
              </span>
            )}
            <TextField
              type="password"
              name="password"
              value={password}
              onChange={this.handlePasswordChange}
              className="loginRows"
              id="standard-basic"
              label={i18next.t("password")}
              onKeyDown={this.handleEnterKey}
            />
            {noPassword && (
              <span className="credentialsRequired">
                {i18next.t("password_required")}
              </span>
            )}
            {isAuthing ? (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ marginTop: "1rem" }}
              >
                <Grid item xs={3}>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <div className="entranceButton">
                <Button className="entranceButton" onClick={this.handleSubmit}>
                  {i18next.t("submit")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
